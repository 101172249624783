import GameArea from './GameArea';
import usePayTable from './PayTable/usePayTable';
import { SupportedPayTable } from './PayTable/usePayTable/types';
import useGameState from './state/useGame';
import AlertDialog from 'alert/AlertDialog';
import BurgerProvider from 'burger/BurgerProvider';
import useBalanceData from 'casino/balance';
import useWagerExecutor from 'casino/wager';
import WagerExecutor from 'casino/wager/WagerExecutor';
import { useToggleInProgressAlert } from 'gaming/LoseInProgress';
import { useCallback, useEffect, useState } from 'react';

const VideoPokerGame = () => {
  const [checker, changePayTable] = usePayTable();

  const { toggleHold, dealDraw, cycleCoinSize, cycleTotalCoins, ...presentation } = useGameState(checker);
  const { balance, adjustBalance } = useBalanceData();
  const { makeDeduction } = useWagerExecutor();
  const [hasBeenInProgress, setHasBeenInProgress] = useState(false);

  const inProgress = presentation.phase !== 'game-over' && presentation.phase !== 'initial';

  const toggleGameInProgressAlert = useToggleInProgressAlert();

  const [isAlerting, setIsAlerting] = useState(false);
  const clearAlert = useCallback(() => setIsAlerting(false), []);

  useEffect(() => {
    if (inProgress) {
      setHasBeenInProgress(true);
    }

    toggleGameInProgressAlert(inProgress);
  }, [inProgress, toggleGameInProgressAlert]);

  const makeWager = useCallback(
    () =>
      makeDeduction(presentation.bet, () => {
        dealDraw();
        try {
          // @ts-expect-error
          gtag('event', 'new_game_started', {
            event_category: 'video_poker',
            event_label: 'Deal',
            gameName: checker.name,
          });
        } catch {}
      }),
    [presentation.bet, dealDraw, makeDeduction, checker]
  );

  useEffect(() => {
    if (hasBeenInProgress && !inProgress && presentation.result) {
      adjustBalance(presentation.result.win);
    }
  }, [inProgress, presentation.result, adjustBalance, hasBeenInProgress]);

  const onChangePayTable = useCallback(
    (newTable: SupportedPayTable) => {
      if (inProgress) {
        setIsAlerting(true);
      } else {
        changePayTable(newTable);
      }
    },
    [changePayTable, inProgress]
  );

  return (
    <>
      <GameArea
        onChangePayTable={onChangePayTable}
        presentation={presentation}
        balance={balance}
        onCycleCoinSize={cycleCoinSize}
        onCycleTotalCoins={cycleTotalCoins}
        onDealDraw={inProgress ? dealDraw : makeWager}
        toggleHold={toggleHold}
        checker={checker}
      />
      {isAlerting && (
        <AlertDialog
          title="Game in progress"
          message="Complete your current game before changing the pay table"
          onOk={clearAlert}
        />
      )}
    </>
  );
};

const WrappedVideoPokerGame = () => (
  <WagerExecutor>
    <BurgerProvider>
      <VideoPokerGame />
    </BurgerProvider>
  </WagerExecutor>
);

export default WrappedVideoPokerGame;
