import EnvironmentVariableService from 'environment/EnvironmentVariableService';

const toggle = (setAs: boolean) => {
  // @ts-expect-error known key on window
  window[`ga-disable-${EnvironmentVariableService.get('ANALYTICS_KEY')}`] = setAs;
};

class AnalyticsService {
  enable() {
    toggle(false);
  }

  disable() {
    // We now use consent mode when not opted in
  }
}

export default new AnalyticsService();
