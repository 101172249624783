import styles from './AndroidGames.module.scss';
import AndroidLink, { AndroidLinkProps } from './AndroidLink';
import VegasLogo from './images/Vegas.webp';
import DoubleLogo from './images/doubledown.webp';
import FreecellLogo from './images/freecell.png';
import JokerLogo from './images/jokerwild.webp';
import JumboLogo from './images/jumbo.png';
import PickPairLogo from './images/pickpair.png';
import SpiderLogo from './images/spider.webp';
import VideoPokerClassicLogo from './images/videoPokerClassic.png';

const GameConfigs: AndroidLinkProps[] = [
  {
    iconSrc: VegasLogo,
    name: 'Vegas Solitaire',
    storeUrl: 'https://play.google.com/store/apps/details?id=com.lambtongames.vegassolitairefree',
  },
  {
    iconSrc: SpiderLogo,
    name: 'Spider Solitaire',
    storeUrl: 'https://play.google.com/store/apps/details?id=com.lambtongames.spidersolitairefree',
  },
  {
    iconSrc: DoubleLogo,
    name: 'Double Down Stud',
    storeUrl: 'https://play.google.com/store/apps/details?id=com.lambtongames.videodoubledownstudpokerfree',
  },
  {
    iconSrc: JokerLogo,
    name: 'Joker Wild Poker',
    storeUrl: 'https://play.google.com/store/apps/details?id=com.lambtongames.jokerswild',
  },
  {
    iconSrc: JumboLogo,
    name: 'Jumbo Video Poker',
    storeUrl: 'https://play.google.com/store/apps/details?id=com.lambtongames.jumbovideopokerfree',
  },
  {
    iconSrc: PickPairLogo,
    name: 'Pick A Pair Poker',
    storeUrl: 'https://play.google.com/store/apps/details?id=com.lambtongames.pickapairvideopokerfree',
  },
  {
    iconSrc: FreecellLogo,
    name: 'FreeCell',
    storeUrl: 'https://play.google.com/store/apps/details?id=com.lambtongames.freecell',
  },
  {
    iconSrc: VideoPokerClassicLogo,
    name: 'Video Poker Classic',
    storeUrl: 'https://play.google.com/store/apps/details?id=com.lambtongames.videopokerclassicfree',
  },
];

const AndroidGames = () => {
  return (
    <div className={styles.root}>
      {GameConfigs.map((config) => (
        <AndroidLink key={config.name} {...config} />
      ))}
    </div>
  );
};

export default AndroidGames;
